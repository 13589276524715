
import bus from "@/utils/bus";
import { useRouter, useRoute } from "vue-router";
import PublicButton from "@/components/PublicButton/index.vue";
import MyTable from "@/components/MyTable/index.vue";
import { Search } from "@element-plus/icons-vue";
import {
  defineComponent,
  reactive,
  toRefs,
  onBeforeMount,
  onMounted,
} from "vue";
export default defineComponent({
  name: "",
  components: { PublicButton, MyTable },
  setup() {
    const router = useRouter();
    const route = <any>useRoute();
    const data = reactive({
      btnList: [
        { id: 1, path: "Account", name: "账号管理", highlight: true },
        { id: 2, path: "PeopleManagement", name: "人员管理" },
        { id: 3, path: "TeamOrGroup", name: "班组管理" },
        { id: 4, path: "Tour", name: "交班记录" },
      ],
      input2: "",
      Search: Search,
    });
    onBeforeMount(() => {
      //2.组件挂载页面之前执行----onBeforeMount
    });
    onMounted(() => {
      //3.组件挂载到页面之后执行-------onMounted
      getRoute();
    });

    const getRoute = () => {
      data.btnList?.forEach((item: any) =>
        item.path == route?.name
          ? (item.highlight = true)
          : (item.highlight = false)
      );
    };
    const getCut = (id: any) => {
      let path = data.btnList.filter((item: any) => item.id == id)[0].path;
      router.push(`/Personnel/${path}`);
    };

    const getAdd = () => {
      bus.emit("getDialog");
    };
    return {
      ...toRefs(data),
      getCut,
      getAdd,
      route,
    };
  },
});
